<template>
  <v-card
      tile
      v-if="user"
      :max-width="width"
  >
    <v-img
        :src="banner"
        height="300"
        class="d-flex"
        style="align-items: center; text-align: center"
    >
      <v-avatar size="120" rounded class="rounded-circle mx-auto">
        <v-img
            style="object-fit: cover;"
            :src="avatar"
        />
      </v-avatar>
    </v-img>
    <v-card-title class="primary--text">
      {{ fullname }}
    </v-card-title>

    <v-card-subtitle class="secondary--text">
      {{ nickname }}
    </v-card-subtitle>
    <v-card-text v-if="description" class="text-caption">
      {{ description }}
    </v-card-text>
    <v-card-text class="py-0 text-body-1 primary--text font-weight-bold text-uppercase">
      {{ type }} info
    </v-card-text>
    <v-card-text>
      <v-list-item class="px-0">
        <v-list-item-content>
          <v-list-item-title class="text-body-2 text-uppercase font-weight-bold">User</v-list-item-title>
          <v-list-item-subtitle class="text-caption">
            <router-link :to="'/users/'+user.userId">{{ user.userId }}</router-link>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-card-text>
      <v-list-item class="px-0">
        <v-list-item-content>
          <v-list-item-title class="text-body-2 text-uppercase font-weight-bold">Wallet address</v-list-item-title>
          <v-list-item-subtitle class="text-caption">{{ user.walletAddress }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Wallet Address</v-list-item-title>
              <v-list-item-subtitle>{{ user.walletAddress }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>

export default {
  name: "UserCard",
  props: {
    user: Object,
    type: String,
    width: {
      type: String,
      default: '400'
    }
  },
  data() {
    return {
      show: false
    }
  },

  computed: {
    description() {
      return this.info && this.info.description ? this.info.description : null
    },
    avatar() {
      return this.info && this.info.avatarUrl ? this.info.avatarUrl : require('../assets/images/users/2.png');
    },
    fullname() {
      return this.info && this.info.name ? this.info.name : '';
    },
    nickname() {
      return this.info && this.info.nickname ? `@${this.info.nickname}` : '';
    },
    info() {
      return this.user && this.user.userInfo ? this.user.userInfo : null;
    },
    banner() {
      return this.info && this.info.bannerUrl ? this.info.bannerUrl : require('../assets/images/placeholder.png');
    }
  }
}
</script>

<style scoped>

</style>
