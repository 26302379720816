<template>
  <div>
    <v-card
        :class="classes"
        max-width="500">
      <v-img
          aspect-ratio="0.5"
          :src="token.previewUrl ? token.previewUrl.replaceAll('ipfs://', 'https://cloudflare-ipfs.com/ipfs/'):''"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          max-height="400"
      >
        <v-card-title v-text="token.title"></v-card-title>
      </v-img>
      <v-card-actions class="primary--text d-flex align-center">

        <v-spacer></v-spacer>

        <div class="mr-2">
          <v-icon :color="totalLikes > 0 ? 'error' : 'gray'">mdi-heart</v-icon>
          <span :class="totalLikes > 0 ? 'ml-1 text-body-2 primary--text' : 'ml-1 text-body-2 gray--text'">({{
              totalLikes
            }})</span>
        </div>
        <div>
          <v-icon :color="totalComments > 0 ? 'primary' : 'gray'">mdi-comment-text</v-icon>
          <span :class="totalComments > 0 ? 'ml-1 text-body-2  primary--text' : 'ml-1 text-body-2  gray--text'">({{
              totalComments
            }})</span>
        </div>


      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Flag from "@/components/Common/Flag";

export default {
  name: "TokenCard",
  components: {Flag},
  props: {
    token: Object,
    likes: Object,
    comments: Object,
    classes: String
  },
  data() {
    return {
      showLikes: false,
      loading: false,
    }
  },

  computed: {
    totalComments() {
      return this.comments ? this.comments.totalElements : 0;
    },
    totalLikes() {
      return this.likes ? this.likes.totalElements : 0;
    },
    currency() {
      return this.token ? this.token.currency : null;
    }
  }

}
</script>

<style scoped>

</style>
