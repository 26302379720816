<template>
  <div class="primary rounded-pill py-1 px-4 " style="position: absolute; z-index: 10; top: 10px; left: 10px;">
    <slot name="flag-body">
      <div v-text="label" class="white--text text-caption"></div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "Flag",
  props: {
    label: String,
  }
}
</script>

<style scoped>

</style>
