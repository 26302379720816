<template>

  <v-container v-if="token">
    <base-header>
      <div slot="page-header">
        <div class="d-flex align-center mb-8">
          <back-button/>
          <h2 class="font-weight-black primary--text">{{ token.title }}</h2>
          <v-tooltip top v-if="isExternal">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2">mdi-cloud-download</v-icon>
            </template>
            <span>Not already imported</span>
          </v-tooltip>
        </div>
      </div>
      <div slot="page-search">
        <ul style="list-style-type:  none; float:right" class="flex px-2" v-if="token.status">
          <li>
            <v-btn
                v-if="token.status !== 'BLOCKED'"
                elevation="2"
                :disabled="loading"
                depressed
                dark
                color="error"
                @click="setHideNFt()"
            >
              BLOCK
            </v-btn>
            <v-btn
                :disabled="loading"
                v-if="token.status !== 'AVAILABLE'"
                elevation="2"
                depressed
                dark
                color="success"
                @click="setUnlockNFt()"
            >
              UNBLOCK
            </v-btn>
          </li>
        </ul>
      </div>
    </base-header>
    <v-row>
      <v-col class="col-3">
        <token-card :token="token" :likes="likes" :comments="comments" classes=""/>
      </v-col>
      <v-col class="col-9">
        <v-tabs v-model="tab" background-color="primary" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            DETAIL
            <v-icon>mdi-star</v-icon>
          </v-tab>
          <v-tab href="#tab-2">
            LIKES
            <v-icon>mdi-heart</v-icon>
          </v-tab>
          <v-tab href="#tab-3">
            COMMENTS
            <v-icon>mdi-comment-text</v-icon>
          </v-tab>
          <v-tab href="#tab-4" v-if="owner">
            OWNER
            <v-icon>mdi-account</v-icon>
          </v-tab>
          <v-tab href="#tab-5" v-if="creator">
            CREATOR
            <v-icon>mdi-account</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card-text class="primary--text my-4" v-if="token.description">
              {{ token.description }}
            </v-card-text>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text ">Internal ID
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption secondary--text">{{ token.id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">File url
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption secondary--text"><a
                    target="_blank"
                    :href="token.fileUrl.replaceAll('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')">{{
                    token.fileUrl
                  }}</a></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">IPFS
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption secondary--text"><a
                    target="_blank"
                    :href="token.ipfsHash">{{
                    token.ipfsHash
                  }}</a></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">
                  <div class="d-flex align-center pb-2" style="height: 32px;">
                    <span class="d-block">Preview url</span>
                    <v-tooltip top v-if="token.type === 'IMAGE'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-2"
                            style="width:20px;height:20px;"
                            x-small
                            fab
                            v-bind="attrs"
                            v-on="on"
                            rounded color="#0216f2" @click="optimizePreviewUrl()"
                            v-if="!token.previewUrl || token.previewUrl.indexOf('cloudinary.com') === -1"
                            :disabled="loadingOptimizePreview">
                          <v-icon
                              size="16"
                              dense
                              color="white"
                              v-if="!loadingOptimizePreview"
                          >mdi-rotate-right
                          </v-icon>
                          <v-progress-circular
                              width="2"
                              size="12"
                              v-if="loadingOptimizePreview"
                              indeterminate
                              color="white"
                          ></v-progress-circular>
                        </v-btn>
                      </template>
                      <span>Optimize preview URL</span>
                    </v-tooltip>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption secondary--text">
                  <a style="text-overflow: ellipsis;width:100%;"
                     target="_blank"
                     class="overflow-hidden d-block"
                     :href="token.previewUrl ? token.previewUrl.replaceAll('ipfs://', 'https://cloudflare-ipfs.com/ipfs/'):''">{{
                      token.previewUrl
                    }}</a>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col class="col-6 col-lg-3">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">Status
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption secondary--text">
                      <v-tooltip top v-if="isExternal">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              v-bind="attrs"
                              v-on="on">mdi-cloud-download</v-icon>
                        </template>
                        <span>Not already imported</span>
                      </v-tooltip>
                      {{ token.status }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col class="col-6 col-lg-3">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">Visible Status
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption secondary--text">{{
                        token.visibleStatus
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col class="col-6 col-lg-3">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">External Status
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption secondary--text">{{
                        token.externalStatus
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col class="col-6 col-lg-3">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">Type
                    </v-list-item-title>
                    <v-list-item-subtitle class="secondary--text text-caption">{{ token.type }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col class="col-6 col-lg-3">
                <v-list-item>
                  <v-list-item-content class="text-uppercase font-weight-bold">
                    <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">Block Chain ID
                    </v-list-item-title>
                    <v-list-item-subtitle class="secondary--text text-caption">{{
                        token.blockChainId
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col class="col-6 col-lg-3">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">Currency
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption secondary--text">{{
                        token.currency
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">HASH
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption secondary--text">{{ token.hash }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

            </v-row>
            <v-row>
              <v-col class="col-12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase font-weight-bold text-body-2 primary--text">Contract
                      Address
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption secondary--text">{{
                        token.contractAddress
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="1000">
              <v-data-table
                  @click:row="goToUser"
                  height="60vh"
                  :headers="headers"
                  :loading="loading"
                  :items="likes ? likes.content : []"
                  :page="pagination.page"
                  :footer-props="{
                              'items-per-page-options':[5,10,20],
                              itemsPerPageText:''
                            }"
                  :items-per-page="pagination.rowsPerPage"
                  loading-text="Loading... Please wait"
                  item-key="likedId">
                <template v-slot:item.user.userInfo.avatarUrl="{ item }">
                  <v-avatar size="45px" class="ma-2">
                    <v-img :src="item.user.userInfo.avatarUrl || require('../../assets/images/users/2.png')"></v-img>
                  </v-avatar>
                </template>
              </v-data-table>
            </v-sheet>
          </v-tab-item>
          <v-tab-item value="tab-3">
            <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="1000">
              <v-data-table

                  height="60vh"
                  :headers="headersComments"
                  :loading="loading"
                  :items="comments ? comments.content : []"
                  :page="pagination.page"
                  :footer-props="{
                              'items-per-page-options':[5,10,20],
                              itemsPerPageText:''
                            }"
                  :items-per-page="pagination.rowsPerPage"
                  loading-text="Loading... Please wait"
                  item-key="id">
                <template v-slot:item.created="{ item }">
                  <v-row align="center" class="px-3">
                    <span>{{ new Date(item.created).toLocaleString() }}</span>
                  </v-row>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex align-center" style="gap: 1rem">
                    <v-btn icon x-small @click="setShowComment(item)">
                      <v-icon color="success">mdi-eye</v-icon>
                    </v-btn>
                    <v-btn icon x-small @click="setDeleteComment(item)">
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-sheet>
          </v-tab-item>
          <v-tab-item value="tab-4" v-if="owner">
            <user-card :user="owner" type="Owner" width="100%"/>
          </v-tab-item>
          <v-tab-item value="tab-5" v-if="creator">
            <user-card :user="creator" type="Creator" width="100%"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog v-model="showNftHideConfirm" width="300">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to block {{ token && token.title }}?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showNftHideConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="setNftTokenStatus('BLOCKED')">
          Block
        </v-btn>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="showNftUnlockConfirm" width="300">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to unblock {{ token && token.title }}?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showNftUnlockConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain
               @click="setNftTokenStatus('AVAILABLE')">
          Unblock
        </v-btn>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="showComment" width="600">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto d-inline-block" color="" light>
        <div class="my-2">
          <h4 class="primary--text mb-1">
            {{ selectedComment ? selectedComment.user.userInfo.name : '' }}
          </h4>
          <h5 class="secondary-text mb-1 font-weight-regular">
            @{{ selectedComment ? selectedComment.user.userInfo.nickname : '' }}
          </h5>
        </div>
        <v-divider/>
        <div class="primary--text py-6 text-body-2">
          {{ selectedComment ? selectedComment.description : '' }}
        </div>
        <div class="text-center">
          <v-btn :disabled="loading" class="ma-1" color="primary" plain
                 @click="showComment=false; selectedComment=null">
            Close
          </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="deleteCommentDialog" width="400">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to delete comment <b>{{ selectedComment && selectedComment.id }}</b>?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="deleteCommentDialog=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain
               @click="removeTokenComment(selectedComment ? selectedComment.id : null)">
          Delete
        </v-btn>
      </v-sheet>
    </v-dialog>
  </v-container>

</template>

<script>
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";
import BaseCard from "@/components/Base/BaseCard";
import TokenCard from "@/components/TokenCard";
import Flag from "@/components/Common/Flag";
import UserCard from "@/components/UserCard";
import BackButton from "@/components/Common/BackButton";

export default {
  name: "Token",
  components: {
    BackButton,
    UserCard,
    Flag,
    TokenCard,
    BaseCard,
    BaseHeader
  },
  computed: {
    owner() {
      return this.token && this.token.owner && this.token.owner.userId ? this.token.owner : null;
    },
    creator() {
      return this.token && this.token.creator && this.token.creator.userId ? this.token.creator : null;
    }
  },
  methods: {
    goToUser(item) {
      this.$router.push({path: `/users/${item.user.id}`})
    },
    setDeleteComment(comment) {
      this.selectedComment = comment;
      this.deleteCommentDialog = true;
    },
    setShowComment(comment) {
      this.selectedComment = comment;
      this.showComment = true;
    },
    setHideNFt() {
      this.showNftHideConfirm = true
    },
    setUnlockNFt() {
      this.showNftUnlockConfirm = true
    },
    setNftTokenStatus(status) {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + `/admin/nft/${this.token.id}/status`, {status}).then(resp => {
        this.showNftHideConfirm = false;
        this.showNftUnlockConfirm = false;
        this.fetchToken(this.$route.params.token, this.$route.params.is_external)
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    async removeTokenComment(commentId) {
      if (!commentId) {
        this.deleteCommentDialog = false;
        return;
      }
      this.loading = true;
      axios.delete(process.env.VUE_APP_BASE_API_URL + `/admin/nft/comment/${commentId}`).then(resp => {
        this.selectedComment = null;
        this.fetchTokenComments(this.token.id);
        this.$toast.success('Successfully deleted')
      }).catch(e => {
        this.$toast.error('Ops! There was an error.')
      }).finally(() => {
        this.loading = false;
        this.deleteCommentDialog = false;
      })
    },
    fetchToken(tokenId, isExternal = false) {
      this.loading = true;
      axios.get(process.env.VUE_APP_BASE_API_URL + `/admin/nft/${isExternal ? 'external/' : ''}${tokenId}`)
          .catch(() => {
            this.isExternal = false;
            return axios.get(process.env.VUE_APP_BASE_API_URL + `/admin/nft/${tokenId}`)
          })
          .then(async resp => {
            this.token = resp.data;
            await this.fetchTokenLikes(tokenId);
            await this.fetchTokenComments(tokenId);
          })
          .finally(() => {
            this.loading = false;
          })
    },
    optimizePreviewUrl() {
      this.loadingOptimizePreview = true;
      axios.get(process.env.VUE_APP_BASE_API_URL + `/admin/nft/${this.isExternal ? 'external/' : ''}${this.token.id}/optimize-preview`).then(async resp => {
        this.fetchToken(this.token.id, this.token.externalStatus === 'EXTERNAL');
      }).finally(() => {
        this.loadingOptimizePreview = false;
      })
    },
    async fetchTokenLikes(tokenId) {
      this.loading = true;
      axios.get(process.env.VUE_APP_BASE_API_URL + `/nft/like/token/${tokenId}`, {
        page: 1,
        size: this.pagination.rowsPerPage,
        sort: 'created,desc'
      }).then(resp => {
        this.likes = resp.data;

      }).finally(() => {
        this.loading = false;
      })
    },
    async fetchTokenComments(tokenId) {
      this.loading = true;
      axios.get(process.env.VUE_APP_BASE_API_URL + `/admin/nft/${tokenId}/comment`, {
        page: 1,
        size: this.pagination.rowsPerPage,
        sort: 'created,desc'
      }).then(resp => {
        this.comments = resp.data;

      }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    const tokenId = this.$route.params.token;
    const isExternal = this.$route.query.is_external;
    this.isExternal = isExternal;
    if (tokenId) {
      this.fetchToken(tokenId, isExternal)
    } else {
      this.$router.back();
    }
  },
  data() {
    return {
      tab: null,
      selectedComment: null,
      deleteCommentDialog: false,
      isExternal: false,
      comments: null,
      likes: null,
      token: null,
      loading: false,
      loadingOptimizePreview: false,
      showComment: false,
      showNftHideConfirm: false,
      showNftUnlockConfirm: false,
      pagination: {
        descending: true,
        sortBy: 'created',
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
      },
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          width: '80px',
          value: 'user.userInfo.avatarUrl',
        },
        {
          text: 'Username',
          align: 'start',
          sortable: true,
          value: 'user.userInfo.nickname',
        },
        {
          text: 'Like ID',
          sortable: true,
          value: 'likeId'
        }
      ],
      headersComments: [
        {
          text: 'Comment ID',
          sortable: true,
          value: 'id'
        },
        {
          text: 'Username',
          align: 'start',
          sortable: true,
          value: 'user.userInfo.nickname',
        },
        {
          text: 'Created',
          sortable: true,
          value: 'created'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
